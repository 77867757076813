/**
 * @name: 分账管理-分账账户管理接口文件
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 分账管理-分账账户管理接口文件
 * @update: 2024-05-13 11:37
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { ILedgerAccount } from "./types";

export const ledgerAccountQueryApi = () => get<ILedgerAccount[]>('/admin/cents/account/getList')

export const ledgerAccountModifyApi = (data: Partial<ILedgerAccount>) => postJ('/admin/cents/account/modify', data)

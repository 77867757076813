
/**
 * @name: 分账管理-分账账户管理
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 分账管理-分账账户管理
 * @update: 2024-05-13 11:37
 */
import { Vue, Component } from "vue-property-decorator"
import {
  ledgerAccountQueryApi,
  ledgerAccountModifyApi
} from '@/apis/ledger/account'
import {
  ILedgerAccount
} from '@/apis/ledger/account/types'
import type { ICrudOption } from "@/types/m-ui-crud"

@Component({})
export default class ledgerAccount extends Vue {
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格数据
  tableData: ILedgerAccount[] = []

  // 当前正在编辑
  editId = ''


  getProductTypeText (productType: number) {
    if (productType === 1) {
      return '第三方商品'
    }
    if (productType === 2) {
      return '自营商品'
    }
  }

  getAccountTypeText (accountType: number) {
    if (accountType === 1) {
      return '平台抽佣账号'
    }
    if (accountType === 2) {
      return '政府基金A账号'
    }
    if (accountType === 3) {
      return '政府基金B账号'
    }
    if (accountType === 4) {
      return '贡献池账号'
    }
  }

  objectSpanMethod(data: any) {
    if (data.columnIndex === 0) {
      if (data.rowIndex % 4 === 0) {
        return {
          rowspan: 4,
          colspan: 1
        };
      } else {
        return {
          rowspan: 0,
          colspan: 0
        };
      }
    }
  }

  getList () {
    this.tableLoading = true

    ledgerAccountQueryApi().then(e => {
      if (e) {
        // @ts-ignore
        e.sort((a: ILedgerAccount, b: ILedgerAccount) => a.productType - b.productType);
        // @ts-ignore
        this.tableData = e;
        this.tableLoading = false
      }
    })
  }

  validQty (qty: string) {
    // 判断是否不为0的无效字符
    if (!qty && isNaN(parseInt(qty))) {
      this.$message.error("银行卡号不能为空")
      return false
    }
    // 判断是不是
    if (!/^[A-Za-z\d]+$/.test(qty)) {
      this.$message.error("银行卡号必须是数字、字母")
      return false
    }
    if (qty.length > 20) {
      this.$message.error("银行卡号最大20位")
      return false
    }
    return true
  }

  enterEdit (cardNumber: string) {
    if (this.validQty(cardNumber)) {
      ledgerAccountModifyApi({
        id: this.editId,
        bankCardNumber: cardNumber
      }).then(e => {
        if (e) {
          this.$message.success('操作成功!')
          this.getList()
          this.editId = ''
        }
      })
    }
  }

  openEdit (id: string) {
    if (this.editId) {
      return
    }
    this.editId = id;
  }

  cancelEdit () {
    this.editId = ''
    this.getList()
  }

  created () {
    this.getList()
  }
}
